b<template>
    <section>
        <titlebar :title-stack="titleStack"></titlebar>
        <estudo :estudo="model" :index="0" :selecionado="false" :mostraAccessionNumber="true"></estudo>
        <div class="columns" v-if="this.model.guiaId && this.model.exames[0].exameId">
            <div class="column is-2">
                    <div v-for="(item, index) in this.model.exames" v-bind:key="index">
                        <a :href="'/guia/resultado?guiaId=' + model.guiaId + '&item=' + item.item">
                            <b-button is-fullwidth class="button is-info is-fullwidth is-justify-content-flex-start" title="Laudo Estruturado">
                                <b>&nbsp;&nbsp;{{$t('ESTUDOLAUDO.LAUDOESTRUTURADO')}}</b>
                            </b-button>
                        </a>
                    </div>
                    <p class="panel-heading">
                        {{$t('ESTUDOLAUDO.BUSCARLAUDO')}}
                    </p>
                    <div class="panel-block">
                        <b-input v-model="laudoDigitado"
                                    icon="magnify"
                                    @input="procurarLaudoAutocomplete"
                                    @keydown.native.down="laudoIndex++"
                                    @keydown.native.up="laudoIndex--"
                                    @keydown.native.enter.prevent="carregaLaudo(listLaudos[laudoIndex])"
                                    ref="laudoDigitadoInput"></b-input>
                    </div>

                    <template v-for="(laudo, index) in filteredListLaudos" >
                        <template v-if="index == 0 || listLaudos[index - 1].id != laudo.id">
                            <a class="panel-block columns is-multiline" :key="index" @click="carregaLaudo(laudo)" v-bind:class="{ 'has-background-primary': laudoIndex == index }">
                                <div class="column is-6">
                                    <p>{{ laudo.nome }}</p>
                                </div>
                            </a>
                        </template>
                    </template>
            </div>
            <div class="column is-10">
                <ckeditor
                    editorUrl="https://cdn.ckeditor.com/4.19.1/full-all/ckeditor.js"
                    :config="editorConfig"
                    v-model="model.report"
                    ></ckeditor>
            </div>
        </div>
        <div v-else class="column is-12">
            <ckeditor
                editorUrl="https://cdn.ckeditor.com/4.19.1/full-all/ckeditor.js"
                :config="editorConfig"
                v-model="model.report"
                ></ckeditor>
        </div>
        <nav class="level">
            <div class="level-item">
                <p class="buttons">
                            <!-- v-show="isInRole('estudo-laudar')" -->
                    <b-button type="is-success"
                            :loading="salvandoDados"
                            @click="salvar()"
                            icon-left="check-circle">
                        {{$t('ESTUDOLAUDO.SALVAR')}}
                    </b-button>
                    <b-button type="is-danger"
                            native-type="button"
                            icon-left="close-circle"
                            @click="$router.back();">
                        {{$t('ESTUDOLAUDO.CANCELAR')}}
                    </b-button>
                </p>
            </div>
            <div class="level-right">
                <div class="level-item">
                    <b-field>
                        <b-switch v-model="validado">{{$t('ESTUDOLAUDO.EHVALIDO')}}</b-switch>
                    </b-field>
                </div>
            </div>
        </nav>
    </section>
</template>

<script>
    import { mapGetters } from 'vuex'
    import titlebar from '@/components/titlebar.vue'
    import estudo from '@/components/atendimento/estudo.vue'
    import CKEditor from 'ckeditor4-vue'
    import modalRetificacaoLaudo from '../../components/modalRetificacaoLaudo.vue'
    import debounce from 'lodash/debounce'
    import utilResizing  from '../../assets/js/views/estudos-laudo/utilResizing.js'


    export default {
        data() {
            return {
                model: null,
                salvandoDados: false,
                editorConfig: {
                    // An array of stylesheets to style the WYSIWYG area.
                    // Note: it is recommended to keep your own styles in a separate file in order to make future updates painless.
                    contentsCss: [ 'https://cdn.ckeditor.com/4.19.1/full-all/contents.css', '/css/ckeditor-document.css', '/home/resultadocss' ],

                    // Define the toolbar: https://ckeditor.com/docs/ckeditor4/latest/features/toolbar.html
                    // The full preset from CDN which we used as a base provides more features than we need.
                    // Also by default it comes with a 3-line toolbar. Here we put all buttons in a single row.
                    toolbar: [
                        { name: 'clipboard', groups: [ 'clipboard', 'undo' ], items: [ 'Cut', 'Copy', 'Paste', 'PasteText', 'PasteFromWord', '-', 'Undo', 'Redo' ] },
                        { name: 'styles', items: [ 'Font', 'FontSize' ] },
                        { name: 'basicstyles', items: [ 'Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat', 'CopyFormatting' ] },
                        { name: 'colors', items: [ 'TextColor', 'BGColor' ] },
                        { name: 'align', items: [ 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock' ] },
                        { name: 'links', items: [ 'Link', 'Unlink' ] },
                        { name: 'paragraph', items: [ 'NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'Blockquote' ] },
                        { name: 'insert', items: [ 'Image', 'Table' ] },
                        { name: 'mode', items: [ 'Source' ] }
                    ],

                    //TODO: não está aguardando a promise de resizing, sendo assim não guarda os resultados. 
                    // on: {
                    //     paste: async function (ev) {
                    //         if(ev.data.dataValue.includes('data:image/png;base64'))
                    //         {
                    //             ev.cancel;

                    //             const splitBase64 = ev.data.dataValue.split(',');
                    //             const base64 = splitBase64[1].replace('" />','');
                    //             const resized = await utilResizing.resizeBase64Img('data:image/png;base64,'+base64,50,50);
                    //             ev.data.dataValue = `<img src="${resized}"/>`;

                    //             ev.data.dataValue = resized;
                    //         }
                    //     }
                    // },

                    startupFocus: true,

                    // Since we define all configuration options here, let's instruct CKEditor to not load config.js which it does by default.
                    // One HTTP request less will result in a faster startup time.
                    // For more information check https://ckeditor.com/docs/ckeditor4/latest/api/CKEDITOR_config.html#cfg-customConfig
                    customConfig: '',

                    // Sometimes applications that convert HTML to PDF prefer setting image width through attributes instead of CSS styles.
                    // For more information check:
                    //  - About Advanced Content Filter: https://ckeditor.com/docs/ckeditor4/latest/guide/dev_advanced_content_filter.html
                    //  - About Disallowed Content: https://ckeditor.com/docs/ckeditor4/latest/guide/dev_disallowed_content.html
                    //  - About Allowed Content: https://ckeditor.com/docs/ckeditor4/latest/guide/dev_allowed_content_rules.html
                    disallowedContent: 'img{width,height,float}',
                    extraAllowedContent: 'img[width,height,align]',

                    // Enabling extra plugins, available in the full-all preset: https://ckeditor.com/cke4/presets-all
                    extraPlugins: 'tableresize,uploadimage,uploadfile',

                    /*********************** File management support ***********************/
                    // In order to turn on support for file uploads, CKEditor has to be configured to use some server side
                    // solution with file upload/management capabilities, like for example CKFinder.
                    // For more information see https://ckeditor.com/docs/ckeditor4/latest/guide/dev_ckfinder_integration.html

                    // Uncomment and correct these lines after you setup your local CKFinder instance.
                    // filebrowserBrowseUrl: 'http://example.com/ckfinder/ckfinder.html',
                    // filebrowserUploadUrl: 'http://example.com/ckfinder/core/connector/php/connector.php?command=QuickUpload&type=Files',
                    /*********************** File management support ***********************/

                    // Make the editing area bigger than default.
                    height: 800,

                    // This is optional, but will let us define multiple different styles for multiple editors using the same CSS file.
                    bodyClass: 'document-editor',

                    // Reduce the list of block elements listed in the Format dropdown to the most commonly used.
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    format_tags: 'p;h1;h2;h3;pre',

                    // Simplify the Image and Link dialog windows. The "Advanced" tab is not needed in most cases.
                    removeDialogTabs: 'image:advanced;link:advanced',

                    // Define the list of styles which should be available in the Styles dropdown list.
                    // If the "class" attribute is used to style an element, make sure to define the style for the class in "mystyles.css"
                    // (and on your website so that it rendered in the same way).
                    // Note: by default CKEditor looks for styles.js file. Defining stylesSet inline (as below) stops CKEditor from loading
                    // that file, which means one HTTP request less (and a faster startup).
                    // For more information see https://ckeditor.com/docs/ckeditor4/latest/features/styles.html
                    stylesSet: [
                        /* Inline Styles */
                        { name: 'Marker', element: 'span', attributes: { 'class': 'marker' } },
                        { name: 'Cited Work', element: 'cite' },
                        { name: 'Inline Quotation', element: 'q' },

                        /* Object Styles */
                        {
                            name: 'Special Container',
                            element: 'div',
                            styles: {
                                padding: '5px 10px',
                                background: '#eee',
                                border: '1px solid #ccc'
                            }
                        },
                        {
                            name: 'Compact table',
                            element: 'table',
                            attributes: {
                                cellpadding: '5',
                                cellspacing: '0',
                                border: '1',
                                bordercolor: '#ccc'
                            },
                            styles: {
                                'border-collapse': 'collapse'
                            }
                        },
                        { name: 'Borderless Table', element: 'table', styles: { 'border-style': 'hidden', 'background-color': '#E6E6FA' } },
                        { name: 'Square Bulleted List', element: 'ul', styles: { 'list-style-type': 'square' } }
                    ]
                },
                validado: false,
                retificado: false,
                laudoDigitado: null,
                listLaudos: [],
                isFetching: false,
                laudoIndex: 0,

            }
        },
        computed: {
            titleStack() {
                return [
                    'Análise',
                    'Estudo',
                    'Laudo'
                ]
            },
            ...mapGetters([
                'isInRole'
            ]),
            filteredListLaudos() {
                return this.laudoDigitado
                    ? this.listLaudos
                        .filter(x => x.nome.toUpperCase().indexOf(this.laudoDigitado.toUpperCase()) > -1)
                    : this.listLaudos
            },
        },
        components: {
            titlebar,
            estudo,
            'ckeditor': CKEditor.component,
        },
        async mounted() {
            await this.$http.get(`/api/analitico/estudo?id=${this.$route.params.id}`)
                .then(({ data }) => {
                    this.model = data
                })
                .catch((error) => {
                    this.model = null
                    throw error
                })
            await this.loadListaLaudos();
        },
        methods: {
            salvar() {
                if(this.model.exames.filter(x => x.assinado).length > 0 && !this.retificado) {
                    this.abrirRetificacaoLaudo();
                }else {
                    this.salvandoDados = true
                    this.$http.post('/api/analitico/estudolaudo', { id: this.model.id, report: this.model.report, validado: this.validado })
                    .then(() => {
                        this.$router.back()
                    })
                    .catch(err => {
                        Object.keys(err.body.errors).forEach(x => {
                            this.$store.commit('ADD_ERRO', x + ": " + err.body.errors[x])
                        })
                        this.$buefy.toast.open({
                            duration: 5000,
                            message: 'Erro ao salvar laudo',
                            type: 'is-danger',
                            queue: false
                        })
                        window.scrollTo({ top: 0, behavior: 'smooth' });
                    })
                    .finally(() => {
                        this.salvandoDados = false
                    })
                }
            },
            abrirRetificacaoLaudo() {
                this.$buefy.modal.open({
                    parent: this,
                    component: modalRetificacaoLaudo,
                    props: {
                        id: this.model.id,
                        guiaId: this.model.guiaId,
                        selecionado: this.model.exames,
                        validado: this.validado,
                    },
                    events: {
                        salvar: this.salvar,
                        retificacaoConcluida: this.retificacaoConcluida
                    },
                    hasModalCard: true,
                    trapFocus: true
                })
            },
            retificacaoConcluida() {
                this.retificado = true;
            },
            carregaLaudo(laudo){
                if (!laudo){
                    this.$buefy.dialog.alert({
                        title: this.$t('ESTUDOLAUDO.TITULOMODELOLAUDO'),
                        message: this.$t('ESTUDOLAUDO.MESANGEMMODELOLAUDO'),
                        type: 'is-warning',
                        hasIcon: true
                    });
                    return;
                }

                let html = laudo.html
                html = html ? `</br> ${html}` : html
                this.model.report += html
            },
            procurarLaudoAutocomplete: debounce(function () {
                this.loadListaLaudos();
            }),
            loadListaLaudos(){
                this.isFetching = true
                const params = [];
                params.push(`exameId=${this.model.exames[0].exameId}`);
                params.push(`guiaId=${this.model.guiaId}`);

                this.$http.get(`/api/modelolaudodescritivo/ListaLaudosExame?${params.join('&')}`)
                    .then(({ data }) => {
                        this.listLaudos = data;
                        this.laudoIndex = 0;
                        this.$refs.laudoDigitadoInput.focus()
                    })
                    .catch((error) => {
                        this.listLaudos = []
                        throw error
                    })
                    .finally(() => {
                        this.isFetching = false
                    })

            }
        }
    }
</script>
