
<template>
    <section>        
        <b-loading :active.sync="isLoading"></b-loading>


        <titlebar :title-stack="titleStack"></titlebar>
        <div class="tile is-ancestor">
            <div class="tile is-parent is-vertical is-12">
                <article class="tile is-child box">
                    <div class="columns is-12">
                        <div class="column is-3">
                            <b-field label="Filtrar por exames">
                                <b-taginput
                                    v-model="examesSelecionados"
                                    :data="examesLista"
                                    autocomplete
                                    :allow-new="false"
                                    :before-adding="(item) => { this.examesLista = this.exames; return item;}"
                                    @input="loadTiposAgendamentos"                                    
                                    field="nome"
                                    icon="label"                                    
                                    placeholder="Selecione os exames"
                                    @typing="buscarExames">
                                    <template slot-scope="props">
                                        <div class="media">   
                                            <div class="media-left">
                                                <span class="tag is-pulled-right is-light">{{ props.option.id }}</span>
                                            </div>
                                            <div class="media-content">
                                                {{ props.option.nome }}
                                            </div>                           
                                        </div>
                                    </template>
                                </b-taginput>
                            </b-field>
                        </div>
                        <div class="column is-3">
                            <searchIdName table="Convenio" :label="$t('SISTEMA.CONVENIO')"  :id.sync="convenioIdAgendar">
                            </searchIdName>
                        </div>
                    </div>
                    <div class="columns is-multiline is-mobile is-gapless">
                        <b-checkbox-button class="column is-2" v-for="(tipo) in tiposAgendamentoAtivos" v-bind:key="tipo.id" 
                                v-model="filtrosAgendamentoId"
                                :native-value="tipo.id">
                            <b-icon icon="rectangle"  v-if="tipo.cor" :style="tipoAgendamentoStyle(tipo)"></b-icon>           
                            <span>{{tipo.nome}}</span>
                        </b-checkbox-button>
                    </div>
                </article>

                <div v-show="!isLoading" class="columns whitebox"> 
                    <div style="border-right: 3px #ced8ce solid;" class="column is-fullwidth-mobile p-0" v-for="(agenda, index) in agendas" v-bind:key="index">
                        <b-collapse  aria-id="agendaDias">
                            <template slot="header">
                                <span>  {{$t('AGENDA.DIARIO')}}  </span>
                            </template>
                            <div class="columns is-multiline whitebox" v-show="!isLoading">                                                                               
                                <div class="column is-12 has-text-centered whitebox" > 
                                        <b-button class="is-pulled-left"  icon-left="chevron-left" @click="addDays(-1,agenda);" ></b-button>                                    
                                        <span class="is-size is-inline-block mt-2"><b>{{$moment(agenda.dataDiaFiltro).format('DD/MM/YYYY')}} - {{ getDayOfWeek(agenda.dataDiaFiltro)}}</b></span>                                     
                                        <b-button class="is-pulled-right" icon-left="chevron-right"  @click="addDays(1,agenda);" ></b-button> 
                                </div>                                 
                                <agendar-item v-if="agenda.eventosDia.length>0" :eventos="agenda.eventosDia" @incluirNovoHorarioDia="incluirNovoHorarioDia"  @loadDataDia="loadDataDia" :exibirCheckBox="true"></agendar-item>                                  
                                <div v-else class="notification column is-12 is-warning">
                                    <h4>{{$t('AGENDA.ESCALASNAOCADASTRADAS')}}</h4>                                    
                                </div>                                                              
                            </div>
                        </b-collapse>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import {  mapState,mapGetters } from 'vuex'
    import titlebar from '@/components/titlebar.vue';
    import moment from 'moment';    
    import agendarItem from '@/components/atendimento/agendar-item.vue';
    import searchIdName from '@/components/searchIdName.vue';
        
 

    export default {
        components: {
            titlebar,
            agendarItem,
            searchIdName,
            
        },

        computed: {
            ...mapState([                
                'config',
                'unidadeId',
                'componentes'
            ]),  
            ...mapGetters([
                'isInRole'
            ]) ,                    
            titleStack() {
                return [
                    this.$t('AGENDA.ATENDIMENTO'),
                    this.$t('AGENDA.AGENDA'),
                ]
            }
        },
        watch: {
            unidadeId: function(novaUnidade) {                 
                this.$route.query.unidadeId = novaUnidade;                
                this.$forceUpdate();
                this.loadDataDia();                                                
            },
            filtrosAgendamentoId: function(value) {
                if(value.length > 0){
                    this.loadDataDia()
                }
            },
            exames: function() {
                this.examesLista = this.exames
            },
        },        
        data() {
            return {                                                     
                filtrosAgendamentoId: [],
                tiposAgendamentoAtivos: [],
                agendas: [],
                ano: null,
                mes: null,
                exames: [],            
                examesSelecionados: [],
                examesLista: this.exames,
                isLoading: false,
                novo: {
                    data: null,
                    observacao: null,
                    idPaciente: null,
                    idTipoAgendamento: null,
                    funcionarioId: null,
                    paciente: {},
                    tipoAgendamento: {}
                },             
                dataDiaFiltro: new Date(new Date().setHours(0,0,0,0)),
                convenioIdAgendar: null,
            }
        },
        created() {
            try {                
                if (this.$route.query.ano) this.ano = parseInt(this.$route.query.ano);
                if (this.$route.query.mes) this.mes = parseInt(this.$route.query.mes);

                if (this.$route.query.dia) {
                    this.dataDiaFiltro = new Date(0)
                    this.dataDiaFiltro.setDate(this.$route.query.dia)
                    this.dataDiaFiltro.setMonth(this.mes - 1)
                    this.dataDiaFiltro.setFullYear(this.ano)
                    this.dataDiaFiltro.setHours(0,0,0,0)
                }
                
            } catch (ex) {
                console.error(ex);
            }
        },
        mounted() {        
            this.componentes.sidebarIsOpened = false;
            //this.loadDataDia();
            this.loadTiposAgendamentos();
        },
        beforeDestroy() {
            this.componentes.sidebarIsOpened = true;
        },        
        methods: {
            buscarExames(text) {
                this.$http.get(`/Search/Exame?nome=${text}`)
                .then(({ data }) => {
                    this.exames = [];
                    data.forEach((item) => this.exames.push(item))
                })
                .catch((error) => {
                    this.exames = []
                    throw error
                })
                .finally(() => {
                    this.isFetching = false
                })

            },
            addDays(dia,agenda){ 
                
                this.isLoading = true;
                agenda.dataDiaFiltro.setDate(agenda.dataDiaFiltro.getDate() + dia);
                const params = [];
                if (agenda.dataDiaFiltro != null) params.push(`dataDiaFiltro=${this.$moment(agenda.dataDiaFiltro).format('YYYY-MM-DDTHH:mm:ss')}`);
                if (this.$route.query.unidadeId != null) params.push(`unidadeId=${this.$route.query.unidadeId}`);
                if (agenda.tipoAgendamentoId) params.push(`tipoAgendamentoId=${agenda.tipoAgendamentoId}`);                

                this.$http.get(`/api/agenda/AddDays?${params.join('&')}`)
                .then(res => res.data)
                .then(data => {
                    agenda.eventosDia = data?.eventos                                    
                        .map(d => {
                            return this.getEvento(d);
                        });
                })
                .finally(() => {                                    
                    this.$forceUpdate(); 
                    this.isLoading = false;
                });                
                                
            },                                       
            getDayOfWeek(dataDiaFiltro) {
                const day = ["Domingo", "Segunda", "Terça", "Quarta", "Quinta", "Sexta", "Sábado"];
                return day[this.$moment(dataDiaFiltro).toDate().getDay()];
            },   
            getEvento(d) {
                return {
                    ...d,
                    atendimentoInterno: !d.guiaTipoDeLocalColetaDomiciliar,
                    atendimentoDomiciliar: d.guiaTipoDeLocalColetaDomiciliar,
                    day: moment(d.dataHora).format('YYYY-MM-DD'),
                    hour: moment(d.dataHora).format('HH:mm'),
                    data: moment(d.dataHora),
                }
            },
            loadTiposAgendamentos(){
                const params = [];
                
                if (this.$route.query.unidadeId != null) params.push(`unidadeId=${this.$route.query.unidadeId}`);
                if (this.examesSelecionados.length > 0) params.push('examesId=' + this.examesSelecionados.map(item => item.id).join());
                if (this.filtrosAgendamentoId.length > 0) params.push('filtroTipoAgendamento=' + this.filtrosAgendamentoId.map(item => item).join());
                
                this.isLoading = true;

                try {
                    this.$http.get(`/api/agenda/tiposAgendamentoAtivos?${params.join('&')}`)
                        .then(res => res.data)
                        .then(data => {                            
                            this.tiposAgendamentoAtivos = data;                            
                            this.agendas=null;
                                              
                        }).catch(e => {
                            throw e;
                        }).finally(() => {                                    
                            this.$forceUpdate(); 
                            this.isLoading = false;
                        });
                        
                } catch (e) {
                    console.error(e);
                    this.isLoading = false;
                }

            },            
            loadDataDia(){
                const params = [];
                              
                if (this.dataDiaFiltro != null) params.push(`dataDiaFiltro=${this.$moment(this.dataDiaFiltro).format('YYYY-MM-DDTHH:mm:ss')}`);                                     
                if (this.$route.query.unidadeId != null) params.push(`unidadeId=${this.$route.query.unidadeId}`);
                if (this.examesSelecionados.length > 0) params.push('examesId=' + this.examesSelecionados.map(item => item.id).join());
                if (this.filtrosAgendamentoId.length > 0) params.push('filtroTipoAgendamento=' + this.filtrosAgendamentoId.map(item => item).join());
                
                this.isLoading = true;

                try {
                    this.$http.get(`/api/agenda/Agendar?${params.join('&')}`)
                        .then(res => res.data)
                        .then(data => {
                            if (data != null) {    

                                data.forEach((a, index) => {
                                    a.eventosDia = a.eventosDia.map(d => { return this.getEvento(d); });
                                    a.dataDiaFiltro =  new Date(this.$moment(a.dataDiaFiltro).toDate().setHours(0,0,0,0));
                                });                                
                                
                                this.agendas = data;

                            }                            
                        }).catch(e => {
                            throw e;
                        }).finally(() => {                                    
                            this.$forceUpdate(); 
                            this.isLoading = false;
                        });
                        
                } catch (e) {
                    console.error(e);
                    this.isLoading = false;
                }

            },            
            tipoAgendamentoStyle(tipo) {
                return 'color: #' + tipo.cor + '!important'
            },
            incluirNovoHorarioDia(evento, horario,bloqueio){      
                const params = {};
                
                if (evento.tipoAgendamentoId != null) 
                    params.tipoAgendamentoId = evento.tipoAgendamentoId;

                if (horario != null) 
                    params.horario = moment(horario).toDate(); 

                if(bloqueio){
                    this.adicionarAgendamento(evento);
                }

                 //Editar Paciente
                if(evento.id && evento.id>0){
                    params.id = evento.id;
                    
                    this.$router.push({
                        name: 'agendamento', params: params
                    })

                //Novo paciente redirecionamento listagem paciente
                }else if(evento.equipments.length > 0) {
                    this.adicionarAgendamento(evento, true);
                } 
                //Novo paciente Padrão
                else{                                    
                    this.$router.push({
                        name: 'agendamento', params: params
                    })  

                }                  
            },
            async adicionarAgendamento(evento, equip) {
                const params = [];
                if (evento.id) params.push(`id=${evento.id}`);

                evento.tipoAgendamentoId == null 
                    ? params.push(`tipoAgendamentoId=${evento.tipoAgendamento.id}`)
                    : params.push(`tipoAgendamentoId=${evento.tipoAgendamentoId}`) 

                evento.pacienteId == null 
                    ? params.push(`pacienteId=${evento.paciente.id}`) 
                    : params.push(`pacienteId=${evento.pacienteId}`)


                params.push(`datahora=${moment(evento.data).format('YYYY-MM-DDTHH:mm:ss')}`);

                if(evento.funcionarioId == null) evento.funcionarioId = 0;
                params.push(`funcionarioId=${evento.funcionarioId}`);

                params.push(`datahora=${moment(evento.data).format('YYYY-MM-DDTHH:mm:ss')}`);

                params.push(`observacao=${evento.observacao ? evento.observacao : 'Reservado'}`);

                if (this.$route.query.unidadeId != null)
                    params.push(`unidadeId=${this.$route.query.unidadeId}`)
                else
                    params.push('unidadeid=');

                if (this.id && evento.observacao!='Bloqueio' ) params.push(`guiaId=${this.id}`);
                
                try {
                    let url = `/api/agenda/agendaincluir?${params.join('&')}`;

                    if (evento.id) {
                        url = `/api/agenda/agendaEditar?${params.join('&')}`;
                    }

                    this.isLoading = true;
                    const res = await this.$http.post(url)
                    .then(res => res.data)
                    .then(data => {
                        if(equip) {
                            const examesId = this.examesSelecionados.map(item => item.id).join();
                            this.$router.push({ name: 'pacientes', params: {procurar: null, criarGuia: true, agendamento: data.id, examesId: examesId ? examesId : '', convenioIdAgendar: this.convenioIdAgendar } })
                        }
                        else if (data.mensagem != null && data.mensagem != "") {
                           this.$buefy.toast.open({
                            duration: 5000,
                            message: data.mensagem,
                            type: 'is-danger',
                            queue: false
                        })
                        }                                    
                    });   

                   
                    this.loadDataDia();
                    
                                            
                } catch (e) {                    
                    console.error(e);
                    this.isLoading = false;
                }

            },                      
        }
    }
</script>