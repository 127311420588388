import { render, staticRenderFns } from "./exame.vue?vue&type=template&id=829f0576&scoped=true&"
import script from "./exame.vue?vue&type=script&lang=js&"
export * from "./exame.vue?vue&type=script&lang=js&"
import style0 from "./exame.vue?vue&type=style&index=0&id=829f0576&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "829f0576",
  null
  
)

export default component.exports